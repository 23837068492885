<template>
  <div class="misc-wrapper">
    <!-- Brand logo-->
    <b-link
      :to="{ path: '/' }"
      class="brand-logo"
    >
      <logo />

      <h2 class="brand-text text-primary ml-1">
        {{ appName }}
      </h2>
    </b-link>
    <!-- /Brand logo-->

    <div class="misc-inner p-2 p-sm-3">
      <b-img
        center
        height="256px"
        :src="imgUrl"
        alt="public offer page"
      />
      <div class="w-100 text-justify">
        <h1 class="text-center p-2">
          Правила користування <br><small>(Публічна оферта)</small>
        </h1>
        <ol>
          <li><strong>ЗАГАЛЬНІ ПОЛОЖЕННЯ</strong></li>
        </ol>
        <p>1.1. Цей документ є відкритою пропозицією (далі - Оферта) ТОВ &laquo;CЕТВЕЙ&raquo;, юридичної особи, зареєстрованої за законодавством України, код ЄДРПОУ:44488762 (далі - Компанія), яка адресована невизначеному колу осіб, укласти договір про надання послуг (далі - Договір) на викладених в цій Оферті умовах, включаючи всі її додатки.</p>
        <p>1.2. Компанія публікує цю Оферту на інтернет-сайті onetimejob.app (далі - Сайті). Оферта набуває чинності з моменту її розміщення на Сайті.</p>
        <p>1.3. Відповідно до ст. 638 Цивільного кодексу України, в разі прийняття викладених в Оферті умов і оплати послуг, фізична або юридична особа, яка провадить акцепт цієї Оферти, стає Користувачем (акцепт Оферти рівнозначний укладенню Договору на умовах, викладених в Оферті).</p>
        <p>1.4. Компанія залишає за собою право вносити зміни в Оферту (в тому числі в опис послуг, тарифів, порядку оплати) і / або відкликати Оферту в будь-який момент на свій розсуд. У разі внесення змін, такі зміни вступають в силу з моменту розміщення оновленої редакції Оферти на Сайті відповідно до положень п. 1.2. цієї Оферти, якщо інше не зазначено в Оферті.</p>
        <p>1.5. У цій Оферті, якщо контекст не вимагає іншого, наведені нижче терміни мають таке значення:</p>
        <p>1.5.1. <strong>Реєстрація</strong> &mdash; процедура заповнення Користувачем реєстраційної форми на Сайті, а саме: імені користувача, логіну (телефон), паролю та коду-підтвердження для доступу до Сервісів Замовника/Виконавця і входу в Особистий кабінет.</p>
        <p>1.5.2. <strong>Користувач</strong> &mdash; будь-яка дієздатна фізична особа, фізична особа-підприємець або юридична особа, яка прийняла умови цього Договору і користується Сервісами Компанії.</p>
        <p>1.5.3. <strong>Виконавець </strong>&nbsp;&mdash; зареєстрований Користувач, який розміщує Оголошення про надання послуг та Пропозиції на Сайті відповідно до умов цього Договору. Зареєстрований Користувач може як надавати послуги, тобто бути у якості Виконавця, так і розміщувати запити на послуги, тобто бути у якості Замовника</p>
        <p>1.5.4. <strong>Замовник</strong> &mdash; зареєстрований Користувач, який розміщує Завдання (запит на послугу) на Сайті відповідно до умов цього Договору, а також самостійно та / або програмою Сайту автоматично, здійснює Вибір Виконавця для отримання послуг / результатів робіт з виконання Завдання. Зареєстрований Користувач може як надавати послуги, тобто бути у якості Виконавця, так і розміщувати запити на послуги, тобто бути у якості Замовника</p>
        <p>1.5.5. <strong>Авторизація</strong> &mdash; введення зареєстрованим Користувачем своїх логіна і пароля для доступу до Сервісів Сайту і входу в Особистий кабінет.</p>
        <p>1.5.6. <strong>Адміністрація Сайту</strong> &mdash; Компанія і / або інші особи (субпідрядники), уповноважені належним чином Компанією на управління Сайтом і надання Сервісів Користувачам при використанні Сайту відповідно до цього Договору.</p>
        <p>1.5.7. <strong>Баланс</strong> &mdash; рахунок Користувача на Сайті, що відображає суму перерахованих Користувачем Компанії грошових коштів в якості Гарантійного платежу, який може використовуватися для оплати за надані Компанією Сервіси Користувачеві.</p>
        <p>1.5.8. <strong>Вибір Виконавця</strong> &mdash; акцепт Пропозиції Виконавця Замовником Завдання або програмою Сайту автоматично (при вказівці Замовником такого типу вибору Виконавця при створенні Завдання).</p>
        <p>1.5.9. <strong>Відгук</strong> &mdash; коментарі Користувача на Сайті з приводу його взаємин з іншим Користувачем в процесі надання послуг / виконання робіт.</p>
        <p>1.5.10. <strong>Гарантійний платіж (Підписка)</strong> &mdash; грошова сума, яка перераховується Виконавцем на банківський рахунок Компанії і відображається на Балансі і є, узгодженим Сторонами (Виконавцем і Компанією) відповідно до положень ч. 2 ст. 546 Цивільного кодексу України, способом забезпечення виконання грошових зобов'язань Виконавцем перед Компанією стосовно оплати Компанії наданого доступу і Сервісів.</p>
        <p>1.5.11. <strong>Завдання або Запит послуги</strong> &mdash; інформація, розміщена Замовником на Сайті, адресована зацікавленим Виконавцям, яка містить запит на надання/виконання послуг/робіт в певній категорії.</p>
        <p>1.5.12. <strong>Закриті Завдання </strong>&mdash; завдання, які були виконані Виконавцем, або закриті Замовником з інших причин, або закриті програмою Сайту автоматично в результаті бездіяльності Замовника або Виконавця більше 5-ти календарних днів з дати закінчення терміну виконання / надання робіт / послуг, зазначеного в Завданні.</p>
        <p>1.5.13. <strong>Медіа файли </strong>&nbsp;&mdash; фотографії (аватари) Користувачів, прикріплені фото/ відео файли до Завдань, прикріплені фото/відео до Оголошень, додані фото/відео файли, як приклад виконаних робіт в портфоліо Виконавця.</p>
        <p>1.5.14. <strong>Компанія</strong> &mdash; Товариство з обмеженою відповідальністю &laquo;CЕТВЕЙ&raquo;, з місцезнаходженням за адресою: 18029, Черкаська область, місто Черкаси, вул. Корольова Академіка, будинок 16/1, кв.237, код ЄДРПОУ: 44488762</p>
        <p>1.5.15. <strong>Логін</strong> &mdash; ідентифікатор Користувача при авторизації на Сайті, який він використовує в процесі користування Сервісами Компанії відповідно до цього Договору. Як Логін використовуються номер мобільного телефону або адреса електронної пошти Користувача. Забороняється реєструвати і використовувати кілька Логінів одним і тим же Користувачем. При цьому не допускається використання Користувачем Логіна, який вже був використаний іншим Користувачем.</p>
        <p>1.5.16. <strong>Обліковий запис</strong> &mdash; електронний кабінет Користувача (аккаунт) в функціональній системі Сайту, за допомогою якого він може керувати своїми Сервісами.</p>
        <p>1.5.17. <strong>Оголошення або Пропозиція послуги</strong> &mdash; розміщення на Сайті пропозиції Виконавця у вигляді повідомлень - тексту і графічних зображень, що описують досвід роботи і компетентність Виконавця в конкретній категорії надання робіт/послуг.</p>
        <p>1.5.18. <strong>Особистий кабінет</strong> &mdash; сторінка зареєстрованого Користувача на Сайті, за допомогою якої зареєстрований Користувач здійснює управління своїм Обліковим записом (акаунтом) та Балансом (якщо є) і містить наступну інформацію: загальні налаштування профілю, налаштування повідомлень, зміна пароля, контактні телефони, інформація про аккаунти Користувача у соціальних мережах, управління Пропозиціями Послуг, прив'язаними банківськими картами, інформацію про сплачені Підписки, іншою інформацією стосовно Сервісів Сайту та даних Користувача</p>
        <p>1.5.19. <strong>Пароль</strong> &mdash; символьна комбінація, призначена автоматично програмним забезпеченням Сайту в момент реєстрації (надалі Користувач може самостійно змінити пароль), що забезпечує в сукупності з логіном ідентифікацію Користувача при використанні Сервісів Компанії, які надаються відповідно до цього Договору.</p>
        <p>1.5.20. <strong>Правила конфіденційності</strong> &mdash; умови роботи Компанії з конфіденційною інформацією на Сайті. Діюча версія розміщена на Сайті, за <a href="https://onetimejob.app/uk/privacy-policy">посиланням</a>. Правила конфіденційності є невід'ємною частиною Договору.</p>
        <p>1.5.21. <strong>Правила користування Сайтом</strong> &mdash; докладний опис Сервісів Компанії, включаючи їх вартість, а також правила роботи з Сервісом Виконавцем і Замовником. Правила користування Сайтом є невід'ємною частиною Договору</p>
        <p>1.5.22. <strong>Приватна інформація Замовника </strong>&mdash; це інформація яка буде доступна для перегляду лише Виконавцеві, чию пропозицію було прийнято Замовником, а саме: контактні номери телефонів та повна адреса локації в Завданні</p>
        <p>1.5.23. <strong>Приватна інформація Виконавця </strong>&mdash; це інформація, яка буде доступна для перегляду лише Замовнику, який прийняв пропозицію Виконавця, а саме: контактні номери телефонів та повна адреса локації надання послуг</p>
        <p>1.5.24. <strong>Пропозиція</strong> &mdash; оферта Виконавця, що розміщується на Сайті, на адресу Замовників укласти з ним договір на надання послуг / виконання робіт відповідно до Завдання Замовника.</p>
        <p>1.5.25. <strong>Публічний Профіль Виконавця</strong> &mdash; це сторінка Виконавця на якій Виконавець самостійно розміщує (в тому числі редагує, видаляє і додає) інформацію про себе, види і опис послуг та/або робіт, які надає Виконавець, завантажує фотографії, відео, Пропозиції, а також розміщуються Відгуки Замовників і вказується статуси: ТОП-Фахівець/Виконавець тощо</p>
        <p>1.5.26. <strong>Сайт (Портал)</strong> &mdash; Інтернет-ресурс onetimejob.app - сукупність програмних і апаратних засобів, результат комп'ютерного програмування у вигляді онлайн сервісу, який розміщений в мережі Інтернет за адресою: <a href="https://onetimejob.app/">https://onetimejob.app</a>.</p>
        <p>1.5.27. <strong>Сервіси Компанії</strong> &mdash; сукупність послуг, що надаються Компанією, відповідно до цього Договору для Користувачів на платній або безоплатній основі в тому числі, але не виключно: послуги реєстрації Користувачів, створення Облікового запису (Особистого кабінету), доступ до сервісу особистих повідомлень, доступ до Сайту для розміщення на Сайті Завдань Замовників, Оголошень і Пропозицій Виконавців, доступ до створення Публічного Профілю Виконавця і розміщення інформації в ньому, а також вчинення за допомогою Сайту дій, пов'язаних з наданням можливості Замовникам здійснити вибір Виконавця для надання/виконання послуг/робіт в певній категорії.</p>
        <p>1.5.28. <strong>Мінімальна Сума Виконання </strong>&mdash; мінімальна вартість послуг / робіт Виконавця, яку зазначено в Пропозиції Виконавця, яка може включати вартість діагностики, транспортні витрати Виконавця, пов&rsquo;язані із виїздом до Замовника за вказаною у Запиті послуги адресою, тощо.</p>
        <p>1.6. У разі відсутності однозначного трактування термінів у цій Оферті, Сторони будуть керуватися трактуванням термінів, які використовуються на Сайті відповідно до чинного законодавства України</p>
        <p>1.7. Всі договори про надання послуг і/або виконання робіт укладаються безпосередньо між Користувачами (Виконавцем і Замовником). Таким чином, Компанія не є учасником (стороною) таких договорів, а тільки надає комунікаційну платформу. Права і обов'язки користувачів визначаються чинним законодавством України, в тому числі Законом України "Про електронну комерцію" і Законом України "Про захист прав споживачів".</p>
        <ol start="2">
          <li><strong>ПРЕДМЕТ ДОГОВОРУ</strong></li>
        </ol>
        <p>2.1. В порядку та на умовах визначених в цьому Договорі, Компанія надає доступ за допомогою мережі Інтернет до програмної продукції у вигляді онлайн-сервісу onetimejob.app, який розміщений на Сайті Компанії: <a href="https://onetimejob.app/">https://onetimejob.app</a>, і забезпечує створення Облікових записів, розміщення Публічного Профілю Виконавця, а також створення і розміщення Пропозиції та Оголошень та надання інших Сервісів Компанії (далі - Доступ) і Додаткових послуг, а Користувач зобов'язується прийняти та оплатити такі надані послуги.</p>
        <p>2.2. Акцептом Оферти (моментом повного підтвердження і беззастережного прийняття всіх умов Оферти, її додатків, правил, доповнень, які є його невід'ємними частинами) є момент будь-якої взаємодії Користувача з Сайтом в тому числі, але не виключно: реєстрація на Сайті, внесення та/або поповнення Гарантійного платежу (Підписки), фактичне користування Сервісами Сайту.</p>
        <p>2.3. Факт здійснення будь-якої взаємодії на Сайті означає, що Користувач ознайомлений, розуміє і беззастережно приймає умови Оферти в повному обсязі без застережень і обмежень.</p>
        <p>2.4. Крім тексту цього Договору, порядок надання Сервісів Компанією визначаються <a href="https://onetimejob.app/uk/privacy-policy">Політикою конфіденційності</a>, яка є невід'ємною частиною Договору.</p>
        <p>2.5. Користувач самостійно здійснює вибір Сервісів Компанії доступ до яких і/або послугами яких він має намір отримати або скористатися.</p>
        <p>2.6. Компанія має право залучати третіх осіб (субпідрядників) для надання Користувачам всіх або частини послуг з використання та/або доступу до Сайту.</p>
        <ol start="3">
          <li><strong>ПОРЯДОК НАДАННЯ І ВИКОРИСТАННЯ СЕРВІСІВ</strong></li>
        </ol>
        <p>3.1. Сервіси надаються Компанією тільки для Користувачів.</p>
        <p>3.2. Особа, яка бажає отримати статус Підтвердженого Користувача, зобов'язана пройти процедуру реєстрації на відповідній сторінці Сайту. При реєстрації Користувач зобов'язується надати в реєстраційній формі (анкеті) достовірну, повну і точну інформацію про себе і забезпечувати її актуальність і повноту. Користувач зобов'язується не вводити Компанію і / або інших Користувачів в оману щодо своєї особи / найменування, а також не розміщувати на Сайті адреси, номери телефонів, адреси електронної пошти, паспортні / реєстраційні дані та іншу інформацію будь-яких третіх осіб. У разі виявлення Адміністрацією Сайту недостовірної інформації, яку розмістив Користувач, або інформації, що може вводити в оману інших Користувачів, Адміністрація Сайту має право відмінити таку реєстрацію та / або призупинити її і / або припинити надання доступу до Сервісів Сайту і надання послуг з використання Сервісів і / або вимагати додаткові документи для перевірки такої, розміщеної Користувачем інформації. В такому випадку Адміністрація Сайту направляє відповідне повідомлення Користувачу.</p>
        <p>3.3. Користувач несе відповідальність за дотримання прав (майнових та немайнових) третіх осіб щодо інформації, переданої (наданої) Компанії при реєстрації Користувача, використанні Сайту і отриманні Сервісів Компанії. З моменту реєстрації на Сайті Користувач дає згоду і погоджується на обробку Компанією персональних даних Користувача, зазначених ним при реєстрації, (збір, реєстрація, накопичення, зберігання, адаптування, зміна, поновлення, уточнення, використання і поширення, реалізація, передача, знеособлення, знищення ) з метою забезпечення можливості надання Послуг, а також з метою інформаційного обслуговування Користувача.</p>
        <p>3.4. Користувач погоджується з тим, що дії, вчинені на Сайті після реєстрації Користувача, визнаються діями Користувача. Користувач несе повну відповідальність за збереження конфіденційності його Облікового запису (в тому числі Логіна і Пароля) і за діяльність, яка відбувається з його Обліковим записом.</p>
        <p>3.5. Користувач не має права передавати, продавати, передавати в користування тощо свої Логін і Пароль для доступу до Сайту і Сервісів Компанії третім особам без згоди Компанії. У разі передачі Логіна і Пароля будь-якій третій особі, всю відповідальність за дії такої третьої особи несе Користувач.</p>
        <p>3.6. У разі порушення безпеки або несанкціонованого використання Облікового запису Користувача, він повинен негайно повідомити про це Компанію. Компанія не несе відповідальності за будь-які збитки, спричинені несанкціонованим використанням Облікового запису Користувача.</p>
        <p>3.7. Реєструючись, Користувач погоджується на отримання інформаційних повідомлень на вказану при реєстрації адресу електронної пошти та / або номер мобільного телефону.</p>
        <p>3.8. Після проходження процедури реєстрації Виконавця може бути застосована процедура перевірки, під якою розуміється надання Компанії персональних / реєстраційних даних Виконавця і їх підтвердження.</p>
        <p>3.8.1. Для проходження процедури перевірки Виконавцю-фізичній особі необхідно надати селфі з паспортом у вигляді книжечки (на фотографії паспорт в розгорнутому вигляді з актуальною фотографією повинен бути на рівні очей і відстані не більше, ніж 20 см від обличчя Користувача; така фотографія повинна бути читабельною) або id-карти; для іноземців та осіб без громадянства допускається надання селфі з посвідкою на постійне проживання (у вигляді книжечки або id-карти) та посвідкою на тимчасове проживання (у вигляді id-карти). Фотографія видаляється протягом 24 годин після завершення перевірки.</p>
        <p>3.8.2. Для проходження процедури перевірки Виконавцю-фізичній особі-підприємцю необхідно надати свідоцтво про державну реєстрацію та/або виписку/витяг з Єдиного державного реєстру юридичних осіб, фізичних осіб - підприємців (далі також - ЄДР).</p>
        <p>3.8.3. Для проходження процедури перевірки Виконавцю-юридичній особі необхідно надати Компанії свідоцтво про державну реєстрацію юридичної особи та/або виписку/витяг з ЄДР, а також документи, що підтверджують повноваження відповідного представника юридичної особи на вчинення дій від його імені, пов'язаних із замовленням Сервісів Компанії по цього Договору.</p>
        <p>3.8.4. Для проходження процедури перевірки Виконавцю-фізичною особою, що провадить незалежну професійну діяльність необхідно надати Компанії довідку про взяття на облік платника податків, відомості щодо якого не підлягають включенню до ЄДР та документ (дозвіл, сертифікат тощо), що підтверджує право фізичної особи на провадження незалежної професійної діяльності</p>
        <p>3.9. Після проходження перевірки Виконавець отримує статус &ldquo;Перевіреного фахівця&rdquo;.</p>
        <p>3.10. Адміністрація Сайту має право відправити Виконавця на повторне проходження перевірки у випадку наявності запиту Виконавця про зміну/уточнення інформації про себе або зміну Логіну, а також у випадку наявності підозри щодо потенційних шахрайських дій з Обліковим записом Виконавця.</p>
        <p>3.11. Користувач має право в будь-який час припинити використання Сервісів Компанії, повідомивши про це Компанію шляхом направлення відповідного повідомлення.</p>
        <p>3.12. Компанія не гарантує доступність Сайту і Сервісів цілодобово. Компанія має право в будь-який момент відмовити будь-якому Користувачеві в використанні Сервісів в будь-який час та без пояснення причин.</p>
        <p>3.13. Користувачеві надається доступ до сервісу особистих повідомлень. Під сервісом особистих повідомлень розуміється можливість розміщення Користувачем на сторінках Сайту повідомлень і завантаження файлів, які є недоступними для огляду всіма Користувачами, а доступні тільки Замовнику Завдання і Обраному Виконавцю. Забороняється розміщення особистих повідомлень і завантаження файлів, які:</p>
        <p>3.13.1. порушують чинне законодавство України;</p>
        <p>3.13.2. містять спам, схеми фінансових "пірамід";</p>
        <p>3.13.3. є незаконними, шкідливими, загрозливими, такими, що ображають моральність, наклепницькими, такими, що порушують авторські права або інші права інтелектуальної власності третіх осіб, які пропагують ненависть і / або дискримінацію людей за расовою, етнічною, статевою, соціальною ознаками;</p>
        <p>3.13.4. містять посилання на Інтернет-ресурси, що належать Користувачам або третім особам;</p>
        <p>3.13.5. містять контактні дані Користувача (номер телефону, акаунти соціальних мереж або сервісів миттєвих повідомлень);</p>
        <p>3.13.6. порушують права третіх осіб.</p>
        <p>3.14. Компанія має право ознайомлення з історією особистих повідомлень і в будь-який момент видалити приватне повідомлення, яке не відповідає вимогам цього Договору.</p>
        <p>3.15. Користувач зобов'язується:</p>
        <p>3.15.1. Не вживати ніяких дій, які можуть призвести до непропорційно великого навантаження на інфраструктуру Сайту;</p>
        <p>3.15.2. Не використовувати автоматичні програми для отримання доступу на Сайт без письмового дозволу Компанії;</p>
        <p>3.15.3. Не копіювати, не відтворювати, не змінювати, не поширювати і не представляти громадськості будь-яку інформацію, що міститься на Сайті (крім відомостей, наданих самим Користувачем) без попередньої письмової згоди Компанії;</p>
        <p>3.15.4. Не перешкоджати і не намагатися перешкоджати роботі та іншої діяльності на Сайті; а також не перешкоджати дії автоматичних систем або процесів, а також інших заходів, з метою перешкодити або обмежити доступ на Сайт;</p>
        <p>3.15.5. Не використовувати відомості, надані іншими Користувачами, в інших цілях, окрім як для здійснення угоди безпосередньо з цим Користувачем, без письмового дозволу іншого Користувача.</p>
        <p>3.16. Замовники розміщують на Сайті Завдання, виходячи з принципів сумлінності і наявності у них зацікавленості у виконанні / наданні Виконавцем робіт / послуг для Замовника в певній категорії. При цьому забороняється розміщення Замовниками Завдань, що мають на меті вивчення попиту на надання / виконання послуг / робіт і інші цілі, не пов'язані з необхідністю фактичного виконання / надання робіт / послуг.</p>
        <p>3.17. Виконавці самостійно здійснюють пошук нових Завдань і розміщують свої Пропозиції на опубліковані Замовниками Завдання. Розміщення Виконавцем Пропозиції не є гарантією того, що Замовник для виконання свого Завдання вибере саме Виконавця, який розмістив таку Пропозицію.</p>
        <p>3.18. Після Вибору Виконавця Замовник і Виконавець укладають між собою відповідний договір на надання послуг / виконання робіт. Замовник зобов'язаний підтвердити на Сайті факт надання послуг / виконання робіт Виконавцем за укладеним з ним договором, а Виконавець зобов'язується підтвердити факт розрахунку з ним Замовником за виконані / надані роботи / послуги за відповідним договором шляхом розміщення такої інформації у Відгуках.</p>
        <p>3.19. Виконавцю забороняється передоручати виконання Завдань третім особам, в тому числі своїм дружинам, дітям, родичам і друзям.</p>
        <ol start="4">
          <li><strong>ПОРЯДОК ОПЛАТИ СЕРВІСІВ КОРИСТУВАЧЕМ</strong></li>
        </ol>
        <p>4.1. У разі, якщо Виконавець має намір користуватися платними Сервісами сайту, він зобов'язується внести відповідний платіж в розмірі зазначеному у обраному тарифному плані (Підписці) шляхом перерахування грошових коштів на розрахунковий рахунок Компанії через Особистий кабінет до початку користування обраними платними послугами.</p>
        <p>4.2. Оплата Доступу та Додаткових послуг, здійснюється Користувачем відповідно до діючих тарифів Компанії, розміщених на сайті Компанії.</p>
        <p>4.3. Невиконання / неякісне виконання Виконавцем Завдання з вини Виконавця, а також факт неотримання Виконавцем грошових коштів від Замовника в якості оплати за виконане належним чином Завдання, не є підставою для повернення Виконавцю оплати за доступ.</p>
        <p>4.4. Повернення внесеного платежу здійснюється на реквізити, які Виконавець вказує в формі заяви на повернення. Таку заяву буде підтверджено і оброблено Компанією тільки після введення Виконавцем унікально згенерованого коду, спрямованого йому в SMS-повідомленні на номер, який вказав Користувач в Особистому кабінеті. Введення отриманого за допомогою SMS-повідомлення унікально згенерованого коду є електронним підписом Користувача.</p>
        <p>4.5. У разі неможливості надання з вини Компанії з яких-небудь причин Сервісу Користувачеві, Компанія зобов'язується повернути Користувачу оплачену їм суму платежу на підставі відповідної заяви.</p>
        <p>4.6. У разі позитивного вирішення питання про повернення Користувачеві грошових коштів Компанія здійснює таке повернення Користувачеві протягом 10 (десяти) календарних днів з дати заповнення повністю всієї необхідної інформації в заяві на повернення.</p>
        <ol start="5">
          <li><strong>ПРАВА І ОБОВ'ЯЗКИ КОМПАНІЇ</strong></li>
        </ol>
        <p>5.1. Компанія зобов'язується забезпечити можливість отримання Користувачем Сервісів в порядку, визначеному цим Договором.</p>
        <p>5.2. Користувачі погоджуються з тим, що Компанія залишає за собою право на свій власний розсуд змінювати або видаляти будь-яку опубліковану на Сайті інформацію, припиняти, обмежувати або припиняти доступ Користувача до Сервісів Компанії в будь-який час з будь-якої причини.</p>
        <p>5.3. Компанія має право змінювати умови даного Договору тому числі порядок оплати і тарифи Сервісів. Інформація про такі зміни публікується Компанією на Сайті і / або в інформаційній розсилці. Продовження користування Сайтом Користувачем після будь-яких змін Договору означає його згоду з такими змінами та / або доповненнями.</p>
        <p>5.4. Компанія має право розміщувати рекламну і / або іншу інформацію в будь-якому розділі сайту без узгодження з Користувачем.</p>
        <p>5.5. Компанія має право призупиняти, обмежувати або припиняти доступ такого Користувача до будь-якого з Сервісів Компанії в односторонньому порядку в будь-який час, не несучи відповідальності за будь-яку шкоду, яка може бути заподіяна Користувачеві такими діями.</p>
        <p>5.6. Компанія має право здійснювати розсилання Користувачам повідомлень, в тому числі електронних повідомлень на адреси електронної пошти Користувача або SMS-повідомлень на номери мобільних телефонів Користувача, що містять організаційно-технічну, інформаційну чи іншу інформацію про можливості Сервісів Компанії, з метою верифікації номеру телефону Користувача, тощо.</p>
        <p>5.7. Компанія зобов'язується не використовувати облікові дані Користувача, отримані в процесі реєстрації цілях, не передбачених цим Договором та додатками до нього і гарантує нерозголошення цих даних, за винятком випадків, коли розкриття такої інформації є обов'язком Компанії в силу законодавства України.</p>
        <p>5.8. Компанія зобов'язується надавати Користувачу можливість отримання консультацій служби технічної підтримки Компанії по телефону та / або електронній пошті при вказівці Користувачем свого логіна або контактних даних. Обсяг консультацій обмежується конкретними питаннями, пов'язаними з наданням Сервісу. Компанія може на свій розсуд вимагати від Користувача оформлення запиту до служби технічної підтримки по електронній пошті з адреси, вказаної Користувачем при реєстрації.</p>
        <p>5.9. Компанія має право здійснювати модерацію всіх матеріалів розміщених Користувачами</p>
        <ol start="6">
          <li><strong>ПРАВА ТА ОБОВ'ЯЗКИ КОРИСТУВАЧА</strong></li>
        </ol>
        <p>6.1. Користувач зобов'язується виконувати умови цього Договору, а також оплачувати надані йому Сервіси в порядку і на умовах, передбачених цим Договором.</p>
        <p>6.2. Користувач зобов'язується самостійно знайомитися з інформацією про умови надання йому Сервісів Компанією та їх вартості. Продовження користування Сервісом після внесення змін в умови надання послуг розглядається як згода Користувача з внесеними змінами і доповненнями, якщо інше прямо не буде передбачено в таких змінених документах. Всі ризики, пов'язані з настанням несприятливих наслідків, внаслідок недотримання Користувачем вимог цього пункту, несе Користувач.</p>
        <p>6.3. Користувач має право звертатися в службу технічної підтримки Компанії, повідомляючи при цьому свій Логін або контактні дані.</p>
        <p>6.4. Користувач зобов'язується використовувати Сервіси тільки в законних цілях та при використанні дотримуватися чинного законодавства України, зокрема Закону України &laquo;Про забезпечення функціонування української мови як державної&raquo;, а також не порушувати права та законні інтереси Компанії.</p>
        <p>6.5. Користувач не має права вчиняти дій, які впливають на нормальну роботу Сайту і є його несумлінним використанням. Користувач зобов'язується не використовувати будь-які пристрої, програми, процедури, алгоритми і методи, автоматичні пристрої або еквівалентні ручні процеси для доступу, придбання, копіювання або відстеження змісту Сайту.</p>
        <p>6.6. Користувач зобов'язується не здійснювати дій, спрямованих на отримання доступу до чужого особистого профілю, шляхом підбору логіна і пароля, злому або інших дій.</p>
        <p>6.7. Користувач цим підтверджує, що він є повністю дієздатним (щодо фізичних осіб), правоздатним (щодо юридичних осіб), над ним не встановлено піклування в будь-якій формі.</p>
        <p>6.8. Користувач гарантує, що володіє всіма правами на використання матеріалів (контенту), що розміщуються ним при створенні Оголошення.</p>
        <p>6.9. Замовники зобов'язуються не розміщувати Завдання, метою яких є:</p>
        <p>6.9.1. залучення користувачів, а також інших відвідувачів Сайту на сторонні ресурси, сайти або реєстрація користувачів, а також інших відвідувачів Сайту на таких ресурсах, сайтах;</p>
        <p>6.9.2. реклама товарів, робіт і послуг, а також товарів, робіт і послуг, що належать третім особам;</p>
        <p>6.9.3. накрутка або зміна статистики сайтів, числа передплатників в соціальних мережах;</p>
        <p>6.9.4. замовлення автоматичної або ручної розсилки запрошень і повідомлень користувачам соціальних мереж, email-розсилок;</p>
        <p>6.9.5. надання послуг з розповсюдження товарів Замовника.</p>
        <p>6.10. Виконавці зобов'язується не розміщувати на Сайті Оголошення та Пропозиції, які:</p>
        <p>6.10.1. порушують чинне законодавство України;</p>
        <p>6.10.2. містять спам, схеми фінансових "пірамід";</p>
        <p>6.10.3. є незаконними, шкідливими, загрозливими, такими, що ображають моральність, наклепницькими, такими, що порушують авторські права або інші права інтелектуальної власності третіх осіб, пропагують ненависть і / або дискримінацію людей за расовою, етнічною, статевою, соціальною ознаками;</p>
        <p>6.10.4. містять посилання на Інтернет-ресурси, що належать Користувачам або третім особам;</p>
        <p>6.10.5. надання послуг з розповсюдження товарів Замовника;</p>
        <p>6.10.6. містять контактні дані Виконавця або третіх осіб (номер телефону, акаунти соціальних мереж або сервісів миттєвих повідомлень);</p>
        <p>6.10.7. порушують права третіх осіб;</p>
        <p>6.10.8. не мають відношення до обраної категорії послуг.</p>
        <p>6.11. Замовник при Виборі Виконавця допомогою використання Сервісів Компанії усвідомлює і підтверджує, що:</p>
        <p>6.11.1. Компанія і Виконавці є абсолютно незалежними суб'єктами, що не впливають на діяльність один одного;</p>
        <p>6.11.2. Компанія не відповідає за відповідність законодавству України та очікуванням Замовника діяльності, що здійснюється Виконавцем по виконанню робіт, наданню послуг для Замовника;</p>
        <p>6.11.3. Компанія не є стороною електронної угоди між Виконавцем і Замовником, предметом якої виступають роботи і / або послуги, що пропонуються Виконавцями. Всі угоди укладаються безпосередньо між Виконавцями і Замовниками, Компанія не є учасником таких угод , а лише надає комунікаційний майданчик для розміщення Оголошень, Пропозицій, Завдань, а також надає можливість зробити вибір Замовником Виконавця для надання / виконання послуг / робіт в певній категорії. Компанія не несе відповідальності за зміст інформації, що передається або одержуваної інформації і за шкоду, заподіяну в результаті використання Користувачами результатів сервісів Сайту;</p>
        <p>6.11.4. Компанія не несе відповідальності за якість і терміни виконаних / наданих Виконавцями робіт / послуг для Замовника;</p>
        <p>6.11.5. Компанія не несе відповідальності за виконання гарантійних зобов'язань щодо виконаних / наданих Виконавцями робіт / послуг для Замовника;</p>
        <p>6.11.6. Замовник зобов'язується самостійно оформляти свої договірні відносини з вибраними ним Виконавцями (в тому числі: витребувати документи, що засвідчують особу Виконавця при укладанні договорів; витребувати документи, що підтверджують кваліфікацію Виконавця для виконання / надання відповідних робіт / послуг, а також інші, передбачені законодавством України документи, необхідні Виконавцю для виконання / надання робіт / послуг відповідного виду; оформляти договори з Виконавцем в письмовому вигляді і інші документи, підтверджують, в тому числі, передачу Виконавцю коштів за виконані / надані роботи / послуги).</p>
        <p>6.12. Виконавець усвідомлює і підтверджує, що при виборі його Замовником за допомогою використання наданих Компанією Послуг в якості особи, що виконує / надає для Замовника певні роботи / послуги, що:</p>
        <p>6.12.1. Компанія і Замовник є абсолютно незалежними суб'єктами, що не надають вплив на діяльність один одного;</p>
        <p>6.12.2. Компанія не відповідає за виконання Замовником договірних зобов'язань щодо Виконавця, пов'язаних, в тому числі, з оплатою виконаних / наданих їм робіт / послуг;</p>
        <p>6.12.3. Компанія не є стороною електронної угоди між Виконавцем і Замовником, предметом якої виступають роботи і / або послуги, що пропонуються Виконавцями. Всі угоди укладаються безпосередньо між Виконавцями і Замовниками, Компанія не є учасником таких угод , а лише надає комунікаційний майданчик для розміщення Оголошень, Пропозицій, Завдань, а також надає можливість зробити вибір Замовником Виконавця для надання / виконання послуг / робіт в певній категорії . Компанія не несе відповідальності за зміст інформації, що передається або одержуваної інформації і за шкоду, заподіяну в результаті використання Користувачами результатів Сервісів Сайту.</p>
        <p>6.12.4. Виконавець зобов'язується самостійно оформляти договірні відносини з Замовниками, які обрали Виконавця в якості особи, яка буде виконувати / надавати певні роботи / послуги, в тому числі: витребувати документи, що засвідчують особу Замовника при укладанні договорів; оформляти письмово договору з Замовником і інші документи, що підтверджують, в тому числі, виконання / надання робіт / послуг).</p>
        <p>6.13. Користувач повідомлено і він погоджується з тим, що при розміщенні Завдань, на кожному Завданні вказано логотип (знак для товарів і послуг) Компанії, що обумовлено функціоналом надання Сервісу.</p>
        <p>6.14. Користувач має право направляти інформацію Адміністрації Сайту за фактами порушення іншими Користувачами своїх зобов'язань, що випливають з умов укладених з-поміж них договорів, що регламентують виконання / надання робіт / послуг, укладених з використанням наданих Компанією Сервісів.</p>
        <p>6.15. Користувачам категорично забороняється публікація відгуків з метою маніпуляції рейтингом користувачів (Замовників і Виконавців) включаючи:</p>
        <p>6.15.1. фіктивні позитивні відгуки, залишені представниками Виконавців / Замовників або третіми особами з метою маніпуляцій з рейтингом користувачів;</p>
        <p>6.15.2. фіктивні негативні відгуки, залишені Користувачами конкуруючих Виконавців або третіми особами з метою зниження рейтингу.</p>
        <p>6.16. У разі виявлення Адміністрацією Сайту такі відгуки будуть приховані / видалено. Компанія залишає за собою право застосовувати штрафні санкції до Користувачів, які причетні до фальсифікації відгуків.</p>
        <p>6.17. Користувач може мати інші права та нести інші обов'язки, встановлені цим Договором та чинним законодавством України.</p>
        <ol start="7">
          <li><strong>ВІДПОВІДАЛЬНІСТЬ. ОБМЕЖЕННЯ ВІДПОВІДАЛЬНОСТІ</strong></li>
        </ol>
        <p>7.1. Компанія не несе відповідальність за будь-які помилки, упущення, переривання, дефекти і затримки в обробці або передачі даних, збої в лініях зв'язку, знищення будь-якого обладнання, неправомірний доступ третіх осіб до Сайту, що стали причиною обмеження доступу Користувача до Сервісів. Компанія не відповідає за будь-які технічні збої або інші проблеми будь-яких телефонних мереж або служб, комп'ютерних систем, серверів або провайдерів, комп'ютерного або телефонного обладнання, програмного забезпечення, збоїв сервісів електронної пошти або скриптів з технічних причин, за нормальне функціонування і доступність окремих сегментів мережі Інтернет і мереж операторів електрозв'язку, задіяних при здійсненні доступу Користувача до Сервісів.</p>
        <p>7.2. Компанія не відповідає за відповідність Сервісу цілком або його частин очікуванням Користувача, безпомилкову та безперебійну роботу Сервісу, припинення доступу Користувача до Сервісу, а також за збереження Логіна і пароля Користувача, що забезпечують доступ до Сервісів, з причин, пов'язаних з технічними збоями апаратного або програмного забезпечення Компанії, і не відшкодовує Користувачу будь-які пов'язані з цим збитки.</p>
        <p>7.3. При порушенні Користувачами умов цього Договору та / або норм чинного законодавства України, Компанія залишає за собою право обмежити доступ Користувача до Сервісів Компанії на тимчасовій основі, а в разі грубого і / або неодноразового (більше двох разів) порушення умов цього Договору відмовити в доступі до Сервісів на постійній основі.</p>
        <p>7.3.1. У випадку обмеження доступу Виконавцю до Сервісів у зв&rsquo;язку заподіяння ним під час Виконання Завдання збитків Замовнику та/або Компанії у розмірі більше 500 грн, то для відновлення доступу Виконавця до Сервісів він має сплатити Компанії штраф у розмірі 750 грн.</p>
        <p>7.4. Компанія не несе відповідальності перед Користувачем за обмеження доступу до Сервісів, за припинення доступу до Сервісів, якщо ці обмеження і припинення виникли внаслідок обставин непереборної сили, які виникли після укладення цього Договору та на настання яких Сторони не могли вплинути, включаючи, але не обмежуючись, наступним: війна, заколоти, страйки, саботаж, ембарго, пожежі, повені, стихійні лиха, погіршення радіоелектронної або радіологічної обстановки, вибухи, дії та/або бездіяльність уряду України або іншої країни, акти державних органів і / або органів місцевого самоврядування, внесення змін до законодавства України, аварії на мережах загального користування, зміни умов доступу до лінійно-кабельних спорудах зв'язку.</p>
        <p>7.5. Компанія не несе відповідальність перед Користувачем або будь-якими третіми особами за будь-які прямі та / або непрямі збитки, включаючи упущену вигоду або втрачені дані, шкоду честі, гідності або діловій репутації, понесені у зв'язку з використанням ним Сервісів, або неможливості його використання, або несанкціонованого доступу до комунікацій Користувача третіх осіб.</p>
        <p>7.6. Компанія не несе відповідальності за будь-які збитки електронним пристроям Користувача або іншої особи, будь-якого іншого обладнання або програмного забезпечення, викликані або пов&rsquo;язані з використанням Користувачем Сервісів.</p>
        <p>7.7. Компанія не несе відповідальності перед Користувачем або будь-якими третіми особами за:</p>
        <p>7.7.1. зміст і законність, достовірність інформації, використовуваної / одержуваної Користувачем при використанні Сервісів;</p>
        <p>7.7.2. якість робіт / послуг, наданих Виконавцем Замовнику, виконання їх в строк;</p>
        <p>7.7.3. виконання гарантійних зобов'язань перед Замовниками щодо виконаних / наданих Виконавцями робіт / послуг;</p>
        <p>7.7.4. відповідність здійснюваної Виконавцями діяльності по виконанню / надання робіт / послуг Замовникам законодавству України;</p>
        <p>7.7.5. виконання Замовниками своїх зобов'язань по оплаті виконаних / наданих їм Виконавцями робіт / послуг.</p>
        <p>7.8. У разі пред'явлення третіми особами, в тому числі іншим Користувачем, претензій до Компанії, пов'язаних з використанням Користувачем Сервісів Компанії, Користувач зобов'язується своїми силами і за свій рахунок врегулювати зазначені претензії з третіми особами, захистивши Компанію від можливих збитків та спорів, або виступити на стороні Компанії в таких спорах, а також відшкодувати збитки Компанії (включаючи судові витрати), завдані їй у зв'язку з претензіями і позовами, пов'язаними з розміщенням матеріалів і / або діяльністю Користувача на Сайті.</p>
        <p>7.9. Компанія не буде нести відповідальності за будь-які витрати або збитки, прямо або побічно виникли у Замовників або Виконавців в результаті виконання / надання Виконавцями робіт / послуг для Замовників. Компанія ні при яких обставинах не несе відповідальності перед Замовниками, Виконавцями, або третіми особами, за будь-які непрямі збитки, в тому числі упущену вигоду. Відповідальність Компанії обмежується прямими документально підтвердженими збитками, що наступили в результаті дії або бездіяльності Компанії в розмірі, що не перевищує вартість наданих Послуг окремо взятому Користувачеві.</p>
        <p>7.10. Компанія не контролює якість і строки робіт/послуг, що виконуються/надаються Виконавцями, які пропонуються ними за допомогою розміщення відповідної інформації на Сайті. Внаслідок цього Користувач приймає умову, відповідно до якого всі роботи і послуги, що пропонуються через Сайт Виконавцями, надаються ними під свою самостійну відповідальність і надання даних робіт і послуг ніяк не пов'язане з діяльністю Компанії. Замовник приймає на себе повну відповідальність і ризики за надання робіт і послуг, пропонованих Виконавцями за допомогою розміщення відповідної інформації на Сайті. Виконавець бере на себе повну відповідальність і ризики за оплату Замовниками виконаних робіт і послуг Виконавцями.</p>
        <p>7.11. Адміністрація Сайту залишає за собою право видаляти або блокувати доступ до інформації, розміщеної Користувачем без попередження, в разі:</p>
        <p>7.11.1. отримання обов'язкових до виконання рішень компетентних державних органів України;</p>
        <p>7.11.2. вимог власників (і / або уповноважених представників) прав інтелектуальної власності, в т.ч., але не обмежуючись, авторських і / або суміжних прав, прав на знаки для товарів і послуг і т.д., про припинення порушень його прав Користувачем на сайті;</p>
        <p>7.11.3. іншого порушення прав або законних інтересів інших користувачів Сайту, юридичних і фізичних осіб за їх мотивованим зверненням;</p>
        <p>7.11.4. виявлення інформації, яка заборонена до розміщення на Сайті або не відповідає Правилам надання сервісів у відповідності до цього Договору та / або чинного законодавства України.</p>
        <ol start="8">
          <li><strong>ІНТЕЛЕКТУАЛЬНА ВЛАСНІСТЬ</strong></li>
        </ol>
        <p>8.1. Сукупність програм, даних, торгових марок, об'єктів інтелектуальної власності в тому числі об'єктів авторського права, і інших об'єктів, що використовуються на Сайті, є інтелектуальною власністю його законних правовласників і охороняється законодавством про інтелектуальну власність України, а також відповідними міжнародними правовими договорами і конвенціями. Будь-яке використання елементів, символіки, текстів, графічних зображень, програм та інших об'єктів, які входить до складу Сервісу, крім дозволеного в цьому Договорі, без дозволу Компанії чи іншого законного правовласника є незаконним і може послужити причиною для судового розгляду і притягнення порушників до цивільно-правової, адміністративної та кримінальної відповідальності відповідно до законодавства України.</p>
        <ol start="9">
          <li><strong>ПЕРЕПИСКА СТОРІН. СПОВІЩЕННЯ ТА ПОВІДОМЛЕННЯ</strong></li>
        </ol>
        <p>9.1. Листування між Сторонами з приводу виконання цього Договору ведеться по електронній пошті, через адреси, зазначені Користувачами при реєстрації. Адресою електронної пошти, зареєстрованим за Компанією, вважаються електронні адреси support@onetimejob.app.</p>
        <p>9.2. Користувач і Компанія погоджуються, що всі повідомлення і повідомлення, отримані на адреси електронної пошти, зареєстровані для кожної із Сторін в межах послуг, що надаються відповідно до цього Договору, а також публікація змін та доповнень до цього Договору на Сайті вважаються доставленими адресату в належній формі.</p>
        <p>9.3. Користувач і Компанія погодилися, що документи, пов'язані з виконанням цього Договору, передані з використанням електронної пошти, мають юридичну силу.</p>
        <p>9.4. Користувач і Компанія зобов'язується своєчасно перевіряти кореспонденцію, що надходить на адреси електронної пошти.</p>
        <ol start="10">
          <li><strong>ПОРЯДОК ВИРІШЕННЯ СПОРІВ</strong></li>
        </ol>
        <p>10.1. Користувач і Компанія погоджуються, що всі спори і розбіжності, які можуть виникнути з цього Договору або в зв'язку з ним, будуть вирішуватися шляхом переговорів з обов'язковим дотриманням досудового порядку вирішення спорів. Претензії Користувача відносно наданого Компанією Сервісу приймаються до розгляду тільки за умови, якщо вони складені в письмовій формі і спрямовані на поштову адресу Компанії протягом трьох місяців з дня надання Сервісу або відмови в його наданні.</p>
        <p>10.2. У разі, якщо згоди не буде досягнуто, всі суперечки з приводу виконання цього Договору вирішуються в судовому порядку відповідно до чинного законодавства України за місцем знаходження Компанії.</p>
        <p>10.3. У разі виникнення суперечок між Замовником та Виконавцем, пов'язаних з виконанням / наданням Виконавцем робіт / послуг Замовнику або їх оплатою, дані спори вирішуються самостійно між Замовником та Виконавцем без залучення Компанії.</p>
        <ol start="11">
          <li><strong>ПОРЯДОК ВНЕСЕННЯ ЗМІН І ДОПОВНЕНЬ ДО ДОГОВОРУ</strong></li>
        </ol>
        <p>11.1. Зміни та / або доповнення до Договору вносяться в односторонньому порядку за рішенням Компанії. Датою вступу в силу змін і / або доповнень до цього Договору є дата опублікування даних змін і / або доповнень на Сайті, якщо Компанією не вказано інше.</p>
        <p>11.2. У разі незгоди Користувача з внесеними змінами та / або доповненнями до цього Договору, Користувач має право розірвати цей Договір шляхом повідомлення Компанії про відмову Користувача від подальшого використання Сервісів Компанії, а також про незгоду з внесеними змінами та / або доповненнями або про неприєднання до нової редакції договору або про відмову дотримуватися його умови.</p>
        <p>11.3. Сторони домовляються, що мовчання (відсутність письмових повідомлень про розірвання Договору або про незгоду з окремими положеннями Договору, в тому числі зі зміною тарифів), а також продовження використання Сервісів Компанії, визнаються згодою і приєднанням боку до нової редакції Договору, до редакції Договору з урахуванням внесених змін і / або доповнень.</p>
        <ol start="12">
          <li><strong>ЗАКЛЮЧНІ ПОЛОЖЕННЯ</strong></li>
        </ol>
        <p>12.1. Цей Договір і відносини між Компанією та Користувачем регулюються законодавством України. Питання, не врегульовані Договором, підлягають вирішенню відповідно до законодавства України.</p>
        <p>12.2. Якщо з тих чи інших причин будь-які з умов цього Договору будуть визнані недійсними, це не впливає на дійсність або застосовність інших умов Договору.</p>
        <p>12.3. Цей Договір щодо кожного з користувачів вступає в силу з моменту вчинення Користувачем одного з найбільш ранніх дій, зазначених у пункті 2.2. цього Договору та діє до моменту закінчення використання Користувачем Сервісів Компанії.</p>
        <p>12.4. Порядок роботи з Сервісами розміщений за <a href="https://onetimejob.app/uk/how-to-use">посиланням</a></p>
      </div>
      <p class="text-center mt-2">
        <b-link @click="goBack()">
          <feather-icon icon="ChevronLeftIcon" /> {{ $t('Back') }}
        </b-link>
      </p>
    </div>
  </div>
</template>

<script>
import Logo from '@core/layouts/components/Logo.vue'
import { BLink, BImg, BButton } from 'bootstrap-vue'
import store from '@/store/index'
import { $themeConfig } from '@themeConfig'

export default {
  components: {
    BLink, BImg, BButton, Logo,
  },
  data() {
    return {
      downImg: require('@/assets/images/pages/forgot-password.png'),
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/forgot-password.png')
        return this.downImg
      }
      return this.downImg
    },
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
  },
  setup() {
    const { appName } = $themeConfig.app
    return {
      appName,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
